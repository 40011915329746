import React from 'react'
import Layout from '../layouts/';

import styled, { keyframes } from 'styled-components';
import { fadeInDown } from 'react-animations';

const bounceAnimation = keyframes`${fadeInDown}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;


const IndexPage = () => (
  <Layout data={{
    site: {
    siteMetadata: {
      title: 'Rustam Kasad'
    }
    }
    }}>
    <div className="pure-g">
      <BouncyDiv className="pure-u-1 animated">
        WHAT <svg viewBox="0 0 10 10">
          <circle cx={5} cy={5} r={5} />
        </svg>
      </BouncyDiv>
    </div>
  </Layout>
)

export default IndexPage
